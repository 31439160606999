<template class="president-agend">
  <hero-global title="Agenda Presidencial" subtitle="Agenda semanal del Presidente" first-width="78" last-width="22" img="/img/hero/agenda-presidente.svg" />
  <div class="container">
    <section id="agenda-presidencia">
      <div class="container">
        <router-link to="/actualidad#agenda" title="Ir a página de   Órganos de gobierno" alt="Ir a página de   Órganos de gobierno" class="btn-back">Volver
        </router-link>
      </div>
      <div class="column-center container">
        <h2 class="title-grand title__double-center" data-text="Agenda semanal de la Presidencia del CERMI Estatal">Agenda Semanal</h2>
        <p class="subtitle m-bottom-2-rem">Semana del {{firstDayWeek}} al {{lastDayWeek}}</p>

        <div class="box-content container" v-if="store.presidentAgenda">
          <ul class="menu-list container">
            <li v-for="(day, key) in store.presidentAgenda" :key="key" :style="key == 0 ? 'order:2' : 'order:1'">
              <p @click="selectDay(key)" :title="'Ver '+key+' de agenda del presidente'" :alt="'Ver '+key+' de agenda del presidente'" :class="[daySelected == key ? 'active-item' : '']">
                {{$t('translations.daysWeeks.'+key)}}
              </p>
            </li>
          </ul>
          <select name="menu-anclas-responsive" id="menu-anclas-responsive" class="m-bottom-20-px m-top-0-px select-anclas-responsive submenu" v-model="menuDesplegable" v-if="Object.keys(store.subventions)">
            <option v-for="(day, key) in store.presidentAgenda" :key="key" :value="key" >
              {{$t('translations.daysWeeks.'+key)}}
            </option>
          </select>
          <div class="content container" v-if="store.presidentAgenda[daySelected]">
            <b class="title font-family-frank-ruhl ">{{$t('translations.daysWeeks.'+daySelected)}}</b>
            <ul class="list-agenda">
              <li v-for="(eventDay, key) in store.presidentAgenda[daySelected].items" :key="key">
                <span v-if="eventDay.start_hour" class='date'>{{currentHour(eventDay.start_hour)}} h</span>
                <p class='description'>{{eventDay.name}}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </section>
  </div>
</template>

<script>
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import { contentStore } from '@/stores/contents';
  import moment from 'moment'
  export default {
    name: "president-agend",
    components: { HeroGlobal },
    setup() {
      const store = contentStore();
      return {
        store
      }
    },
    mounted() {
      moment.updateLocale('es', {
        week: {
          dow: 1, // Monday is the first day of the week.
        }
      })
      let startDate = moment().startOf('week');
      let endDate = moment().endOf('week');
      this.firstDayWeek = startDate.format('DD/MM/YYYY'),
        this.lastDayWeek = endDate.format('DD/MM/YYYY')
      this.store.loadPresidentAgenda({
        'minDay': startDate,
        'maxDay': endDate
      })

    },
    data() {
      return {
        firstDayWeek: null,
        lastDayWeek: null,
        daySelected: 1,
        menuDesplegable: new Date().getDay(),
      }
    },
    methods: {
      selectDay: function (day) {
        this.daySelected = day
      },
      currentHour(hour) {
        return hour.replace(hour.substr((hour.length - 3), hour.length), '');
      },
    },
    watch: {
      "menuDesplegable"() {
        this.daySelected = this.menuDesplegable
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "@/styles/general.scss";

  #agenda-presidencia {
    padding: 40px 0;
    margin: 40px 0;

    .box-content {
      background: transparent;

      .menu-list {
        width: 15%;
        min-height: auto;
        display: flex;
        flex-direction: column;
      
      p {

        color: #0069B4;
        font-size: 20px;
        margin-bottom: 20px;
        padding: 10px 20px;
        width: 100%;
        display: inline-block;
        font-family: "Frank Ruhl Libre", serif;
        border: none;
        background: none;
        cursor: pointer;
        width: max-content;
        padding: 10px 30px;
        text-transform: capitalize;

        &.active-item {
          background: $blueSuperLight;
          border-radius: 30px;
          font-weight: 600;
        }
      }
    }

    .content {
      box-shadow: 0px 4px 30px rgb(0 105 180 / 30%);
      border-radius: 13px;
      padding: 40px;
      background: white;
      width: 80%;
      min-height: 50vh;

      .title {
        color: $blue;
        margin-bottom: 15px;
        display: inline-block;
        text-transform: capitalize;
      }


    }
  }
  }
</style>